import { HttpClient } from '@angular/common/http';
import { Component, inject, OnInit } from '@angular/core';
import { NotificationsService } from '@core/services/helpers/notifications.service';
import { catchError, firstValueFrom, of } from 'rxjs';

@Component({
  selector: 'app-versioning',
  standalone: true,
  imports: [],
  templateUrl: './versioning.component.html',
  styleUrl: './versioning.component.scss',
})
export class VersioningComponent implements OnInit {
  private api = inject(HttpClient);
  private notif = inject(NotificationsService);
  public changeLogData: string = null;

  get NoData() {
    return (
      !this.changeLogData ||
      (this.changeLogData && this.changeLogData.length === 0)
    );
  }

  async ngOnInit() {
    this.changeLogData = await firstValueFrom(this.getVersionFile());
  }

  getVersionFile() {
    const timestamp = new Date().getTime(); // Generate a unique timestampreturn to resolve cache issues;
    return this.api
      .get(`assets/version/versioning.txt?timestamp=${timestamp}`, {
        responseType: 'text',
      })
      .pipe(
        catchError(() => {
          this.notif.error('Error', 'No changelog file found');
          return of('');
        })
      );
  }
}
