import {
  MapTagIdToLabel,
  MapTagLabelToId,
  SystemTags,
} from '@dashboard/models';

export const TransformTagToId = (tag: SystemTags): string => {
  const id = MapTagLabelToId[tag];
  if (!id) console.warn(`Tag for Id:<${tag}> not found`);
  return id;
};

export const TransformIdToTag = (id: string): SystemTags => {
  const tag = MapTagIdToLabel[id];

  if (!tag) console.warn(`Tag for Id:<${id}> not found`);
  return tag;
};

export const VariableTagToIdTransform = (imo: string, tag: SystemTags) => {
  return `${imo}00000000000${TransformTagToId(tag)}`;
};
