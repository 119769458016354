import { on } from '@ngrx/store';
import { DashboardData } from '../dashboard.state';

import {
  InitDashboardStateAction,
  SetSelectedVesselAction,
  SetVariablesDataAction,
} from '@dashboard-store';
import { createImmerReducer } from 'ngrx-immer/store';

export const DashboardDataState: DashboardData = {};

export const dashboardDataReducer = createImmerReducer(
  DashboardDataState,
  on(InitDashboardStateAction, (state, { varState }) => {
    return { ...varState };
  }),

  on(SetVariablesDataAction, (state, { variableMeta }) => {
    // Use Immer to immutably update the state
    for (const [vesselId, tag, value] of variableMeta) {
      try {
        // Safely check if path exists
        if (state[vesselId] && state[vesselId][tag]) {
          if ('values' in value) {
            state[vesselId][tag].values = value.values;
          } else {
            state[vesselId][tag] = { ...state[vesselId][tag], ...value };
          }
        } else {
          console.warn(`Invalid path: ${vesselId}.${tag}`);
        }
      } catch (error) {
        console.error('Error setting variable data', {
          vesselId,
          tag,
          value,
          error,
        });
      }
    }
    return state;
  }),
  on(SetSelectedVesselAction, (state) => {
    console.log(
      'SetVariablesDataAction REMOVING DATA ====================================='
    );

    const tags = [
      'chart_ME_Power',
      'chart_ME_Power_Ref_HeavyProp',
      'chart_ME_Power_Ref_LightProp',
      'chart_speed_through_water',
      'chart_speed_over_ground',
      'chart_WindSpeedAbsBF',
      'chart_ME_RPM',
      'chart_Current',
      'chart_Slip',
      'chart_ME_FOC_TPD',
      'chart_DraftAft',
      'chart_DraftFor',
      'chart_DraftMidP',
      'chart_DraftMidS',
      'downsample_wind',
      'downsample_engine_speed',
      'downsample_engine_power',
      'downsample_vessel_speed',
      'trace',
      'bar_windspeed_bf',
      'bar_engine_speed',
      'bar_sog',
      'pie_steaming_time',
    ];

    // Use Immer to immutably update the state
    const vesselImos = Object.keys(state); // Get all IMO keys from the state

    for (const imo of vesselImos) {
      for (const tag of tags) {
        if (state[imo]?.[tag]) {
          state[imo][tag] = { ...state[imo][tag], values: [] };
        }
      }
    }
    return state;
  })
);
