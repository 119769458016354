import { dashboardDataReducer } from './dashboard-data.reducers';
import { dashboardUiStateReducer } from './dashboard-ui-state.reducer';

import { ActionReducerMap } from '@ngrx/store';
import { StoreDashboardState } from '../dashboard.state';

const DashboardReducers: ActionReducerMap<StoreDashboardState, any> = {
  // loaders: loadersReducer,
  // vessels: vesselsReducer,
  uiState: dashboardUiStateReducer,
  data: dashboardDataReducer,
};
export { DashboardReducers, dashboardDataReducer, dashboardUiStateReducer };
