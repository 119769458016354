import { inject, Injectable } from '@angular/core';
import { ClusteringRequest } from '@core/api/naviqore-clustering-data-api.service';
import { ExportGraphRequest } from '@core/api/naviqore-data-api.service';
import { StateApiService } from '@core/api/state-api.service';
import { ApiTimeUTC, DateToItsTimezone } from '@core/constants/time.defaults';
import {
  InfoWindowIsActiveSelector,
  ToDateSelector,
  SelectedVesselImo,
  SetSelectedVesselAction,
  VesselsSelector,
  SetVariablesDataAction,
  SetVesselWindowAction,
  SetSystemDatesAction,
  SetAggregationSizeAction,
  SetAggregationStepAction,
  AggregationSizeSelector,
  AggregationStepSelector,
  FromDateSelector,
  SetTimezoneAction,
  TimezoneSelector,
  VesselSidebarApiActions,
  VesselInfoWindoApiActions,
} from '@dashboard-store';
import { ApiEffectsHelperService } from '@dashboard/dashboard-store/effects/api-effects-helper.service';
import {
  DataSeriesVariable,
  DataToApiService,
} from '@dashboard/services/data-to-api.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import calcAggr from '@shared/utils/calcAggregation';
import { catchError, EMPTY, map, of, switchMap, withLatestFrom } from 'rxjs';
@Injectable()
export class ApiEffects {
  private _dataToApi = inject(DataToApiService);
  private actions$ = inject(Actions);
  private _store = inject(Store);
  private _apiEffectHelper = inject(ApiEffectsHelperService);

  ApplyTimezone$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SetTimezoneAction),
      withLatestFrom(
        this._store.select(ToDateSelector),
        this._store.select(FromDateSelector)
      ),
      switchMap(([{ timezone }, _toDate, _fromDate]) => {
        const toDate = DateToItsTimezone(_toDate, timezone);
        const fromDate = DateToItsTimezone(_fromDate, timezone);

        return of(SetSystemDatesAction({ toDate, fromDate }));
      })
    );
  });

  //This effect works when we have a selected IMO
  loadSidebarData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        SetSelectedVesselAction,
        SetSystemDatesAction,
        SetAggregationSizeAction,
        SetAggregationStepAction
      ),

      withLatestFrom(
        this._store.select(ToDateSelector),
        this._store.select(FromDateSelector),
        this._store.select(SelectedVesselImo),
        this._store.select(AggregationSizeSelector),
        this._store.select(AggregationStepSelector),
        this._store.select(TimezoneSelector)
      ),

      switchMap(
        ([
          ,
          toDate,
          fromDate,
          selectedImo,
          aggStep,
          aggType,
          timezoneHours,
        ]) => {
          this._store.dispatch(VesselSidebarApiActions.call());

          const queryDataVars: DataSeriesVariable[] = [];
          const clusteringVars: DataSeriesVariable[] = [];
          console.log(selectedImo);

          if (!selectedImo) return EMPTY;

          if (selectedImo) {
            const sidebardData = this._dataToApi.SidebarQueryPayload();
            queryDataVars.push(...sidebardData);
          }

          const queryDataPayload: ExportGraphRequest = {
            start: ApiTimeUTC(fromDate),
            end: ApiTimeUTC(toDate),
            variables: queryDataVars.map((v) => ({ tagId: Number(v.tagId) })),
            filters: [],
          };

          const clusteringPayload: ClusteringRequest = {
            start: ApiTimeUTC(fromDate),
            end: ApiTimeUTC(toDate),
            variables: clusteringVars.map((v) => {
              let obj = { tagId: Number(v.tagId) };

              if (v.extraPayloadProps) {
                obj = { ...obj, ...v.extraPayloadProps };
              }
              return obj;
            }),
            filters: [],
          };

          if (aggStep)
            queryDataPayload['aggregation'] = calcAggr(aggStep, aggType);

          return this._apiEffectHelper
            .ApiDataRequestsToState(
              selectedImo,
              toDate,
              { queryDataPayload, clusteringPayload },
              { queryDataVars, clusteringVars, timezoneHours }
            )
            .pipe(
              switchMap(([a, b]) => {
                return of(
                  SetVariablesDataAction({
                    variableMeta: [...a, ...b],
                    source: 'API',
                  }),
                  VesselSidebarApiActions.success()
                );
              }),
              catchError((error) => {
                console.error('Error in API request:', error);
                return of(VesselSidebarApiActions.failure());
              })
            );
        }
      )
    );
  });

  loadVesselInfoData$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(
        SetSelectedVesselAction,
        SetSystemDatesAction,
        SetVesselWindowAction,
        SetAggregationSizeAction,
        SetAggregationStepAction
      ),
      withLatestFrom(
        this._store.select(ToDateSelector),
        this._store.select(FromDateSelector),
        this._store.select(SelectedVesselImo),
        this._store.select(InfoWindowIsActiveSelector),
        this._store.select(AggregationSizeSelector),
        this._store.select(AggregationStepSelector),
        this._store.select(TimezoneSelector)
      ),

      switchMap(
        ([
          ,
          toDate,
          fromDate,
          selectedImo,
          infoIsActive,
          aggStep,
          aggType,
          timezoneHours,
        ]) => {
          const queryDataVars: DataSeriesVariable[] = [];
          const clusteringVars: DataSeriesVariable[] = [];

          this._store.dispatch(VesselInfoWindoApiActions.call());

          if (!selectedImo || !infoIsActive) return EMPTY;

          const windoInfoData = this._dataToApi.WindowInfoDataQueryPayload();
          const clusteringData =
            this._dataToApi.WindowInfoClusteringQueryPayload();
          clusteringVars.push(...clusteringData);
          queryDataVars.push(...windoInfoData);

          const queryDataPayload: ExportGraphRequest = {
            start: ApiTimeUTC(fromDate),
            end: ApiTimeUTC(toDate),
            variables: queryDataVars.map((v) => ({ tagId: Number(v.tagId) })),
            filters: [],
          };

          const clusteringPayload: ClusteringRequest = {
            start: ApiTimeUTC(fromDate),
            end: ApiTimeUTC(toDate),
            variables: clusteringVars.map((v) => {
              let obj = { tagId: Number(v.tagId) };

              if (v.extraPayloadProps) {
                obj = { ...obj, ...v.extraPayloadProps };
              }
              return obj;
            }),
            filters: [],
          };

          if (aggStep)
            queryDataPayload['aggregation'] = calcAggr(aggStep, aggType);

          return this._apiEffectHelper
            .ApiDataRequestsToState(
              selectedImo,
              toDate,
              { queryDataPayload, clusteringPayload },
              { queryDataVars, clusteringVars, timezoneHours }
            )
            .pipe(
              switchMap(([a, b]) => {
                return of(
                  SetVariablesDataAction({
                    variableMeta: [...a, ...b],
                    source: 'API',
                  }),
                  VesselInfoWindoApiActions.success()
                );
              }),
              catchError((error) => {
                console.error('Error in API request:', error);
                return of(VesselInfoWindoApiActions.failure());
              })
            );
        }
      )
    );
  });
}
