import { Injectable } from '@angular/core';
import { ApplyTimezoneToLocalDate } from '@core/constants/time.defaults';
import { TimezoneSelector } from '@dashboard/dashboard-store';
import { Store } from '@ngrx/store';
import moment, { Moment } from 'moment';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DatesHelperService {
  $tz = this._store.select(TimezoneSelector);

  constructor(private _store: Store) {}

  /**
   *
   * @returns the current local js time in the selected timezone. Egg
   * if its 13:00 +2 and the timezone is UTC we will get 11:00 +2
   */
  public getNow(_tz?: number) {
    return this.$tz.pipe(
      map((tz) => ApplyTimezoneToLocalDate(moment(), _tz || tz))
    );
  }

  /**
   *
   * @returns the given time which is maybe in  local js time in the selected timezone. Egg
   * if its 13:00 +2 and the timezone is UTC we will get 11:00 +2
   */

  public getDateToLocale(date: Date | string | Moment) {
    return this.$tz.pipe(map((tz) => ApplyTimezoneToLocalDate(date, tz)));
  }

  /**
   * Since date string is in utc . we need to convert it to the local time.
   * and then apply selected timezone
   *
   * @param utcDateTime string date in utc timestamp
   * @returns converted date
   */
  public getDateFromUTCFormat(utcDateTime: string) {
    const convertToLocalTimezone = moment
      .utc(utcDateTime) // Parse as UTC
      .local() // Convert to local timezone
      .format('YYYY-MM-DD HH:mm:ss');
    return this.getDateToLocale(convertToLocalTimezone);
  }

  /**
   *
   * @param utcDate for example 14:00 +0 will be 16:00 +2 (for greece)
   * @returns Moment object
   */
  public convertUTCToLocal(utcDate: string) {
    const date = moment.utc(utcDate).local();
    return {
      moment: () => date,
      formated: () => date.format('YYYY-MM-DD HH:mm:ss'),
    };
  }
}
