type SystemTags =
  | 'ATA'
  | 'ATD'
  | 'BOILER_FOC_TPD_24H'
  | 'COORD'
  | 'CURRENT'
  | 'DESTINATION_PORT'
  | 'DG_FOC_TPD_24H'
  | 'DRAFT_AFT'
  | 'DRAFT_FOR'
  | 'ENGINE_LOAD'
  | 'ETA'
  | 'FUEL_LHV'
  | 'FUEL_TYPE'
  | 'HEADING'
  | 'IS_IN_VOYAGE'
  | 'LOADING_REALTIME'
  | 'LOAD_MARGIN_6H'
  | 'LOAD_MARGIN_EVAL_6H'
  | 'ME_FOC_TPD'
  | 'ME_POWER'
  | 'ME_POWER_REF_HEAVY_PROP'
  | 'ME_POWER_REF_LIGHT_PROP'
  | 'ME_RPM'
  | 'OPERATIONAL_STATUS'
  | 'ORIGIN_PORT'
  | 'RUDDER'
  | 'SOG'
  | 'SOG_REF'
  | 'SPEED_LOSS_PC'
  | 'SPEED_LOSS_PC_6H'
  | 'SPEED_LOSS_EVAL_6H'
  | 'STW'
  | 'SYSTEM_HEALTH'
  | 'WIND_DIR_REL'
  | 'WIND_SPEED_ABS_BF'
  | 'WIND_SPEED_REL_K'
  | 'BOILER_FOC_TPD'
  | 'CARGO'
  | 'CII'
  | 'COURSE'
  | 'DATA_FILTER'
  | 'DEPTH'
  | 'DG_FOC_TPD'
  | 'DISPLACENT'
  | 'DRAFT_MID_P'
  | 'DRAFT_MID_S'
  | 'WWO_AIR_TEMPERATURE'
  | 'WWO_ATM_PRESSURE'
  | 'WWO_CLOUD_COVER'
  | 'WWO_CURRENT_DIR'
  | 'WWO_CURRENT_VELOCITY'
  | 'WWO_HUMIDITY'
  | 'WWO_PERCIPITATION'
  | 'WWO_SIG_WAVE_HEIGHT'
  | 'WWO_VISIBILITY'
  | 'WWO_WEATHER_CODE'
  | 'WWO_WIND_DIR'
  | 'WWO_WIND_SPEED'
  | 'FUEL_DENSITY'
  | 'HULL'
  | 'LAT'
  | 'LIST'
  | 'LOAD_MARGIN'
  | 'LOAD_MARGIN_EVAL'
  | 'LON'
  | 'SLIP'
  | 'SPEED_LOSS_EVAL'
  | 'TRIM'
  | 'WIND_SPEED_REL_M'
  | 'WIND_SPEED_ABS_BF_6H'
  | 'CPA_COMPLIANCE'
  | 'ME_FOC_TPD_24h'
  | 'STEAMING_TIME_24H'
  | 'TOTAL_DISTANCE_24H'
  | 'CPA_COMPLIANCE_6H'
  | 'IS_STEAMING'
  | 'SOSP'
  | 'EOSP'
  | 'VOYAGE_COMPANY_ID'
  | 'DESTINATION_PORT_CODE'
  | 'ORIGIN_PORT_CODE'
  | 'CPA_SPEED'
  | 'CPA_SPEED_MARGIN_KN'
  | 'CPA_FOC'
  | 'CPA_FOC_MARGIN_PC'
  | 'CPA_WIND_SPEED_BF'
  | 'CPA_WAVE_HEIGHT'
  | 'LOADING'
  | 'TIMEZONE'
  | 'NOOA_WIND_SPEED_MS'
  | 'NOOA_WIND_DIRECTION_DEG'
  | 'NOOA_WAVE_HEIGHT_M'
  | 'NOOA_WAVE_DIRECTION_DEG'
  | 'ME_TC_SPEED'
  | 'ME_PUMP_INDEX'
  | 'DG1_POWER'
  | 'DG2_POWER'
  | 'DG3_POWER'
  | 'DG4_POWER'
  | 'VOYAGE_COMMENT'
  | 'ME_FUEL_TEMP'
  | 'ME_VOL_FOC'
  | 'DG_FUEL_TEMP'
  | 'DG_VOL_FOC'
  | 'THROTTLE'
  | 'ALTITUDE'
  | 'PITCH'
  | 'ROLL'
  | 'HEAVE'
  | 'ROT'
  | 'AIR_TEMPERATURE'
  | 'AIR_PRESSURE'
  | 'WATER_TEMPERATURE'
  | 'THRUSTER'
  | 'THRUSTER_POWER'
  | 'THRUSTER_DUTY_CYCLE'
  | 'STEERING_MODE'
  | 'TURN_MODE'
  | 'ORDER_RUDDER_DIRECTION'
  | 'ORDER_RUDDER_ANGLE'
  | 'ORDER_RATE_OF_TURN'
  | 'ORDER_RADIUS_OF_TURN'
  | 'HEADING_TO_STEER_COURSE'
  | 'VOLTAGE_12V'
  | 'CURRENT_12V'
  | 'TEMPERATURE_12V'
  | 'VOLTAGE_48V'
  | 'CURRENT_48V'
  | 'TEMPERATURE_48V'
  | 'CII_RATING_CUMSUM'
  | 'CURRENT_U'
  | 'CURRENT_V';

const MapTagLabelToId: Record<SystemTags, string> = {
  ATA: '10001',
  ATD: '10002',
  BOILER_FOC_TPD_24H: '10003',
  COORD: '10004',
  CURRENT: '10005',
  DESTINATION_PORT: '10006',
  DG_FOC_TPD_24H: '10007',
  DRAFT_AFT: '10008',
  DRAFT_FOR: '10009',
  ENGINE_LOAD: '10010',
  ETA: '10011',
  FUEL_LHV: '10012',
  FUEL_TYPE: '10013',
  HEADING: '10014',
  IS_IN_VOYAGE: '10015',
  LOADING_REALTIME: '10016',
  LOAD_MARGIN_6H: '10017',
  LOAD_MARGIN_EVAL_6H: '10018',
  ME_FOC_TPD: '10019',
  ME_POWER: '10020',
  ME_POWER_REF_HEAVY_PROP: '10021',
  ME_POWER_REF_LIGHT_PROP: '10022',
  ME_RPM: '10023',
  OPERATIONAL_STATUS: '10024',
  ORIGIN_PORT: '10025',
  RUDDER: '10026',
  SOG: '10027',
  SOG_REF: '10028',
  SPEED_LOSS_PC: '10029',
  SPEED_LOSS_PC_6H: '10030',
  SPEED_LOSS_EVAL_6H: '10031',
  STW: '10032',
  SYSTEM_HEALTH: '10033',
  WIND_DIR_REL: '10034',
  WIND_SPEED_ABS_BF: '10035',
  WIND_SPEED_REL_K: '10036',
  BOILER_FOC_TPD: '10037',
  CARGO: '10038',
  CII: '10039',
  COURSE: '10040',
  DATA_FILTER: '10041',
  DEPTH: '10042',
  DG_FOC_TPD: '10043',
  DISPLACENT: '10044',
  DRAFT_MID_P: '10045',
  DRAFT_MID_S: '10046',
  WWO_AIR_TEMPERATURE: '10047',
  WWO_ATM_PRESSURE: '10048',
  WWO_CLOUD_COVER: '10049',
  WWO_CURRENT_DIR: '10050',
  WWO_CURRENT_VELOCITY: '10051',
  WWO_HUMIDITY: '10052',
  WWO_PERCIPITATION: '10053',
  WWO_SIG_WAVE_HEIGHT: '10054',
  WWO_VISIBILITY: '10055',
  WWO_WEATHER_CODE: '10056',
  WWO_WIND_DIR: '10057',
  WWO_WIND_SPEED: '10058',
  FUEL_DENSITY: '10059',
  HULL: '10060',
  LAT: '10061',
  LIST: '10062',
  LOAD_MARGIN: '10063',
  LOAD_MARGIN_EVAL: '10064',
  LON: '10065',
  SLIP: '10066',
  SPEED_LOSS_EVAL: '10067',
  TRIM: '10068',
  WIND_SPEED_REL_M: '10069',
  WIND_SPEED_ABS_BF_6H: '10070',
  CPA_COMPLIANCE: '10071',
  ME_FOC_TPD_24h: '10072',
  STEAMING_TIME_24H: '10073',
  TOTAL_DISTANCE_24H: '10074',
  CPA_COMPLIANCE_6H: '10075',
  IS_STEAMING: '10076',
  SOSP: '10077',
  EOSP: '10078',
  VOYAGE_COMPANY_ID: '10079',
  DESTINATION_PORT_CODE: '10080',
  ORIGIN_PORT_CODE: '10081',
  CPA_SPEED: '10082',
  CPA_SPEED_MARGIN_KN: '10083',
  CPA_FOC: '10084',
  CPA_FOC_MARGIN_PC: '10085',
  CPA_WIND_SPEED_BF: '10086',
  CPA_WAVE_HEIGHT: '10087',
  LOADING: '10088',
  TIMEZONE: '10089',
  NOOA_WIND_SPEED_MS: '10090',
  NOOA_WIND_DIRECTION_DEG: '10091',
  NOOA_WAVE_HEIGHT_M: '10092',
  NOOA_WAVE_DIRECTION_DEG: '10093',
  ME_TC_SPEED: '10094',
  ME_PUMP_INDEX: '10095',
  DG1_POWER: '10096',
  DG2_POWER: '10097',
  DG3_POWER: '10098',
  DG4_POWER: '10099',
  VOYAGE_COMMENT: '10100',
  ME_FUEL_TEMP: '10101',
  ME_VOL_FOC: '10102',
  DG_FUEL_TEMP: '10103',
  DG_VOL_FOC: '10104',
  THROTTLE: '10105',
  ALTITUDE: '10106',
  PITCH: '10107',
  ROLL: '10108',
  HEAVE: '10109',
  ROT: '10110',
  AIR_TEMPERATURE: '10111',
  AIR_PRESSURE: '10112',
  WATER_TEMPERATURE: '10113',
  THRUSTER: '10114',
  THRUSTER_POWER: '10115',
  THRUSTER_DUTY_CYCLE: '10116',
  STEERING_MODE: '10117',
  TURN_MODE: '10118',
  ORDER_RUDDER_DIRECTION: '10119',
  ORDER_RUDDER_ANGLE: '10120',
  ORDER_RATE_OF_TURN: '10121',
  ORDER_RADIUS_OF_TURN: '10122',
  HEADING_TO_STEER_COURSE: '10123',
  VOLTAGE_12V: '10124',
  CURRENT_12V: '10125',
  TEMPERATURE_12V: '10126',
  VOLTAGE_48V: '10127',
  CURRENT_48V: '10128',
  TEMPERATURE_48V: '10129',
  CII_RATING_CUMSUM: '10130',
  CURRENT_U: '10131',
  CURRENT_V: '10132',
} as const;

const MapTagIdToLabel: Record<string, SystemTags> = {
  '10001': 'ATA',
  '10002': 'ATD',
  '10003': 'BOILER_FOC_TPD_24H',
  '10004': 'COORD',
  '10005': 'CURRENT',
  '10006': 'DESTINATION_PORT',
  '10007': 'DG_FOC_TPD_24H',
  '10008': 'DRAFT_AFT',
  '10009': 'DRAFT_FOR',
  '10010': 'ENGINE_LOAD',
  '10011': 'ETA',
  '10012': 'FUEL_LHV',
  '10013': 'FUEL_TYPE',
  '10014': 'HEADING',
  '10015': 'IS_IN_VOYAGE',
  '10016': 'LOADING_REALTIME',
  '10017': 'LOAD_MARGIN_6H',
  '10018': 'LOAD_MARGIN_EVAL_6H',
  '10019': 'ME_FOC_TPD',
  '10020': 'ME_POWER',
  '10021': 'ME_POWER_REF_HEAVY_PROP',
  '10022': 'ME_POWER_REF_LIGHT_PROP',
  '10023': 'ME_RPM',
  '10024': 'OPERATIONAL_STATUS',
  '10025': 'ORIGIN_PORT',
  '10026': 'RUDDER',
  '10027': 'SOG',
  '10028': 'SOG_REF',
  '10029': 'SPEED_LOSS_PC',
  '10030': 'SPEED_LOSS_PC_6H',
  '10031': 'SPEED_LOSS_EVAL_6H',
  '10032': 'STW',
  '10033': 'SYSTEM_HEALTH',
  '10034': 'WIND_DIR_REL',
  '10035': 'WIND_SPEED_ABS_BF',
  '10036': 'WIND_SPEED_REL_K',
  '10037': 'BOILER_FOC_TPD',
  '10038': 'CARGO',
  '10039': 'CII',
  '10040': 'COURSE',
  '10041': 'DATA_FILTER',
  '10042': 'DEPTH',
  '10043': 'DG_FOC_TPD',
  '10044': 'DISPLACENT',
  '10045': 'DRAFT_MID_P',
  '10046': 'DRAFT_MID_S',
  '10047': 'WWO_AIR_TEMPERATURE',
  '10048': 'WWO_ATM_PRESSURE',
  '10049': 'WWO_CLOUD_COVER',
  '10050': 'WWO_CURRENT_DIR',
  '10051': 'WWO_CURRENT_VELOCITY',
  '10052': 'WWO_HUMIDITY',
  '10053': 'WWO_PERCIPITATION',
  '10054': 'WWO_SIG_WAVE_HEIGHT',
  '10055': 'WWO_VISIBILITY',
  '10056': 'WWO_WEATHER_CODE',
  '10057': 'WWO_WIND_DIR',
  '10058': 'WWO_WIND_SPEED',
  '10059': 'FUEL_DENSITY',
  '10060': 'HULL',
  '10061': 'LAT',
  '10062': 'LIST',
  '10063': 'LOAD_MARGIN',
  '10064': 'LOAD_MARGIN_EVAL',
  '10065': 'LON',
  '10066': 'SLIP',
  '10067': 'SPEED_LOSS_EVAL',
  '10068': 'TRIM',
  '10069': 'WIND_SPEED_REL_M',
  '10070': 'WIND_SPEED_ABS_BF_6H',
  '10071': 'CPA_COMPLIANCE',
  '10072': 'ME_FOC_TPD_24h',
  '10073': 'STEAMING_TIME_24H',
  '10074': 'TOTAL_DISTANCE_24H',
  '10075': 'CPA_COMPLIANCE_6H',
  '10076': 'IS_STEAMING',
  '10077': 'SOSP',
  '10078': 'EOSP',
  '10079': 'VOYAGE_COMPANY_ID',
  '10080': 'DESTINATION_PORT_CODE',
  '10081': 'ORIGIN_PORT_CODE',
  '10082': 'CPA_SPEED',
  '10083': 'CPA_SPEED_MARGIN_KN',
  '10084': 'CPA_FOC',
  '10085': 'CPA_FOC_MARGIN_PC',
  '10086': 'CPA_WIND_SPEED_BF',
  '10087': 'CPA_WAVE_HEIGHT',
  '10088': 'LOADING',
  '10089': 'TIMEZONE',
  '10090': 'NOOA_WIND_SPEED_MS',
  '10091': 'NOOA_WIND_DIRECTION_DEG',
  '10092': 'NOOA_WAVE_HEIGHT_M',
  '10093': 'NOOA_WAVE_DIRECTION_DEG',
  '10094': 'ME_TC_SPEED',
  '10095': 'ME_PUMP_INDEX',
  '10096': 'DG1_POWER',
  '10097': 'DG2_POWER',
  '10098': 'DG3_POWER',
  '10099': 'DG4_POWER',
  '10100': 'VOYAGE_COMMENT',
  '10101': 'ME_FUEL_TEMP',
  '10102': 'ME_VOL_FOC',
  '10103': 'DG_FUEL_TEMP',
  '10104': 'DG_VOL_FOC',
  '10105': 'THROTTLE',
  '10106': 'ALTITUDE',
  '10107': 'PITCH',
  '10108': 'ROLL',
  '10109': 'HEAVE',
  '10110': 'ROT',
  '10111': 'AIR_TEMPERATURE',
  '10112': 'AIR_PRESSURE',
  '10113': 'WATER_TEMPERATURE',
  '10114': 'THRUSTER',
  '10115': 'THRUSTER_POWER',
  '10116': 'THRUSTER_DUTY_CYCLE',
  '10117': 'STEERING_MODE',
  '10118': 'TURN_MODE',
  '10119': 'ORDER_RUDDER_DIRECTION',
  '10120': 'ORDER_RUDDER_ANGLE',
  '10121': 'ORDER_RATE_OF_TURN',
  '10122': 'ORDER_RADIUS_OF_TURN',
  '10123': 'HEADING_TO_STEER_COURSE',
  '10124': 'VOLTAGE_12V',
  '10125': 'CURRENT_12V',
  '10126': 'TEMPERATURE_12V',
  '10127': 'VOLTAGE_48V',
  '10128': 'CURRENT_48V',
  '10129': 'TEMPERATURE_48V',
  '10130': 'CII_RATING_CUMSUM',
  '10131': 'CURRENT_U',
  '10132': 'CURRENT_V',
} as const;

export { SystemTags, MapTagLabelToId, MapTagIdToLabel };
