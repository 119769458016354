import valueFromObjPath from '@core/utils/valueFromObjPath';
import { StoreDashboardState } from '@dashboard-store';
import { IXYGraph } from '@dashboard/components/xy-graph/xy-graph.component';
import { IVessel, TraceConfig } from '@dashboard/models';
import {
  createSelector,
  createFeatureSelector,
  createSelectorFactory,
  defaultMemoize,
  MemoizedSelector,
} from '@ngrx/store';
import { isEqual } from 'lodash';

const DeepSelector = createSelectorFactory((projectionFn) =>
  defaultMemoize(
    projectionFn,
    arraysAndObjectsComparison,
    arraysAndObjectsComparison
  )
);

const UiState = createFeatureSelector<StoreDashboardState>('dashboard');

const InfoWindowIsActiveSelector = createSelector(UiState, (state) => {
  return state.uiState.sideBar.isVesselInfoExpaded;
});

const InfoWindowLoadingSelector = createSelector(UiState, (state) => {
  return state.uiState.sideBar.infoExpandedLoading;
});

const SidebarLoadingSelector = createSelector(UiState, (state) => {
  return state.uiState.sideBar.sidebarLoading;
});

const SelectedVesselSelector = createSelector(UiState, (state) => {
  return state.uiState.selectedVessel || null;
});
const IsSidebarOpenSelector = createSelector(UiState, (state) => {
  return state.uiState.sideBar.isSidebarOpen;
});
const SidebarHeadExpandedSelector = createSelector(UiState, (state) => {
  return state.uiState.sideBar.isSidebarHeadExpanded;
});
const AggregationSizeSelector = createSelector(UiState, (state) => {
  return state.uiState.aggSize;
});

const AggregationStepSelector = createSelector(UiState, (state) => {
  return state.uiState.aggStep;
});

const SelectedVesselId = createSelector(
  UiState,
  SelectedVesselSelector,
  (state, activeVesselId) => {
    return state.uiState.vessels[activeVesselId]?._id;
  }
);
const ActiveLayersMapSelector = createSelector(UiState, (state) => {
  return {
    baseLayer: state.uiState.map.selectedBaseLayer,
    overlayLayers: state.uiState.map.selectedOverlayLayers,
  };
});
const WindyObjectLayerSelector = createSelector(
  UiState,
  (state: StoreDashboardState) => {
    return state.uiState.map.windLayers;
  }
) as MemoizedSelector<
  StoreDashboardState,
  StoreDashboardState['uiState']['map']['windLayers']
>;

const WindyLayerSelector = createSelector(UiState, (state) => {
  return state.uiState.map.windLayers.windyType;
});
const SelectedOverlayMapLayers = createSelector(UiState, (state) => {
  return state.uiState.map.selectedOverlayLayers;
});

const SelectedBaseMapLayers = createSelector(UiState, (state) => {
  return state.uiState.map.selectedBaseLayer;
});
const SelectedVesselImo = createSelector(
  UiState,
  SelectedVesselSelector,
  (state, activeVesselId) => {
    return activeVesselId ? state.uiState.vessels[activeVesselId]?.slug : null;
  }
);
const SelectedVesselName = createSelector(
  UiState,
  SelectedVesselSelector,
  (state, activeVesselId) => {
    if (!activeVesselId) return '';
    return state.uiState.vessels[activeVesselId].name.EN;
  }
);

const SelectedVesselLatLng = createSelector(
  UiState,
  SelectedVesselImo,
  (state, activeVesselImo) => {
    if (!activeVesselImo) return undefined;

    return {
      lat: state.data[activeVesselImo].LAT.formattedValue,
      lng: state.data[activeVesselImo].LON.formattedValue,
    };
  }
);
const TimezoneSelector = createSelector(UiState, (state) => {
  return state.uiState.timezone;
});
const ToDateSelector = createSelector(UiState, (state) => {
  return state.uiState.toDate;
});

const FromDateSelector = createSelector(UiState, (state) => {
  return state.uiState.fromDate;
});
const DatesSelector = createSelector(UiState, (state) => {
  return {
    fromDate: state.uiState.fromDate,
    toDate: state.uiState.toDate,
  };
});

const OffsetStepSelector = createSelector(UiState, (state) => {
  return state.uiState.offsetStep;
});

const VesselsSelector = DeepSelector(UiState, (state: StoreDashboardState) => {
  return Object.values(state.uiState.vessels);
}) as MemoizedSelector<StoreDashboardState, Array<IVessel>>;

const VesselMetaSelector = (vesselId: string) =>
  createSelector(UiState, (state) => {
    return state.uiState.vessels[vesselId];
  });

const SelectedMetaSelector = DeepSelector(
  UiState,
  SelectedVesselSelector,
  (state, activeVesselId) => {
    return state.uiState.vessels[activeVesselId];
  }
);

const SelectedParticularsSelector = DeepSelector(
  UiState,
  SelectedVesselSelector,
  (state, activeVesselId) => {
    return state.uiState.vessels[activeVesselId]?.particulars;
  }
);

const SelectedParticularVesselTypeSelector = DeepSelector(
  UiState,
  SelectedVesselSelector,
  (state, activeVesselId) => {
    return state.uiState.vessels[activeVesselId]?.particulars.vesselTypeGeneric;
  }
);

const SelectedReferencesSelector = DeepSelector(
  UiState,
  SelectedVesselSelector,
  (state, activeVesselId) => {
    return state.uiState.vessels[activeVesselId]?.references;
  }
);

const VesselParticularsSelector = (vesselId: string = null) =>
  DeepSelector(UiState, (state) => {
    return state.uiState.vessels[vesselId]?.particulars;
  });

const VesselNameSelector = (vesselId: string = null) =>
  createSelector(UiState, (state) => {
    return state.uiState.vessels[vesselId]?.name.EN;
  });

const VesselImoSelector = (vesselId: string) =>
  createSelector(UiState, (state) => {
    return state.uiState.vessels[vesselId]?.slug;
  });

// const MapLayersSelector = DeepSelector(
//   UiState,
//   (state: StoreDashboardState) => {
//     return state.uiState.map.layers;
//   }
// ) as MemoizedSelector<
//   StoreDashboardState,
//   StoreDashboardState['uiState']['map']['layers']
// >;

// const BaseMapLayersSelector: MemoizedSelector<
//   StoreDashboardState,
//   StoreDashboardState['uiState']['map']['baseLayers']
// > = DeepSelector(UiState, (state: StoreDashboardState) => {
//   return state.uiState.map.baseLayers;
// });

const ActiveTraceIdSelector = DeepSelector(
  UiState,
  (state: StoreDashboardState) => {
    return state.uiState.trace.activeConfigId;
  }
);
const TraceConfigSelector = DeepSelector(
  UiState,
  (state: StoreDashboardState) => {
    return state.uiState.trace.traceConfig;
  }
);
const TraceLoadingSelector = DeepSelector(
  UiState,
  (state: StoreDashboardState) => {
    return state.uiState.trace.loading;
  }
);

const ActiveTraceConfigSelector = DeepSelector(
  UiState,
  ActiveTraceIdSelector,
  TraceConfigSelector,
  (
    state: StoreDashboardState,
    activeId: number,
    config: StoreDashboardState['uiState']['trace']['traceConfig']
  ) => {
    const conf = config.filter((trace) => trace.id === activeId);
    return conf[0] || {};
  }
) as MemoizedSelector<StoreDashboardState, TraceConfig>;

const FleetShowNamesSelector = createSelector(
  UiState,
  (state: StoreDashboardState) => {
    return state.uiState.map.vessels.showName;
  }
);

const FleetLoadingSelector = createSelector(
  UiState,
  (state: StoreDashboardState) => {
    return state.uiState.map.vessels.loading;
  }
);

const ToolsLayerSelector = createSelector(
  UiState,
  FleetShowNamesSelector,
  (state: StoreDashboardState, vesselNameLayer) => {
    return {
      vesselNameLayer,
      dmsTool: state.uiState.map.dms.enabled,
    };
  }
);

function arraysAndObjectsComparison(a: any, b: any): boolean {
  const res = isEqual(a, b);
  return res;
}

const RealtimeIsActiveSelector = createSelector(
  UiState,
  (state: StoreDashboardState) => {
    return state.uiState.realtimeIsActive;
  }
);

const RightBarViewSelector = createSelector(
  UiState,
  (state: StoreDashboardState) => {
    return state.uiState.rightBar.view;
  }
);

const RightBarLoadingSelector = createSelector(
  UiState,
  (state: StoreDashboardState) => {
    return state.uiState.rightBar.loading;
  }
);

const XYStatics = (xyData: IXYGraph) =>
  DeepSelector(
    UiState,
    SelectedVesselSelector,
    SelectedVesselImo,
    (state: StoreDashboardState, vesselId, vesselImo) => {
      const vesselData = state.uiState.vessels[vesselId];
      return {
        lines: xyData.lines.map((lineItem) => ({
          ...lineItem,
          data: vesselData
            ? valueFromObjPath(lineItem.x as string, vesselData).map(
                (lineValue, i) => ({
                  x: lineValue,
                  y: valueFromObjPath(lineItem.y as string, vesselData)[i],
                })
              )
            : [],
        })),

        ranges: {
          x: vesselData
            ? valueFromObjPath(xyData.ranges.x, vesselData)
            : undefined,
          y: vesselData
            ? valueFromObjPath(xyData.ranges.y, vesselData)
            : undefined,
        },
        points: xyData.points.map((point) => ({
          ...point,
          data: [
            {
              x: vesselData
                ? Number(state.data[vesselImo][point.x].formattedValue)
                : undefined,
              y: vesselData
                ? Number(state.data[vesselImo][point.y].formattedValue)
                : undefined,
            },
          ],
        })),
      };
    }
  );

const MapZoomSelector = createSelector(
  UiState,
  (state: StoreDashboardState) => {
    return state.uiState.map.zoom;
  }
);

const MapBoundsSelector = createSelector(
  UiState,
  (state: StoreDashboardState) => {
    return state.uiState.map.bounds;
  }
);

const MapClicksSelector = createSelector(
  UiState,
  (state: StoreDashboardState) => {
    return state.uiState.map.dms;
  }
);

const WindDataLoadingSelector = createSelector(
  UiState,
  (state: StoreDashboardState) => {
    return state.uiState.map.windLayers.loading;
  }
);

const TooltipSelector = createSelector(
  UiState,
  (state: StoreDashboardState) => {
    return state.uiState.tooltipTemplate;
  }
);

const InfoWindowActiveTabSelector = createSelector(
  UiState,
  (state: StoreDashboardState) => {
    return state.uiState.sideBar.activeTab;
  }
);
export {
  SelectedVesselSelector,
  TimezoneSelector,
  ToDateSelector,
  WindDataLoadingSelector,
  FromDateSelector,
  VesselsSelector,
  SelectedVesselImo,
  VesselMetaSelector,
  SelectedVesselName,
  SidebarHeadExpandedSelector,
  VesselImoSelector,
  SelectedVesselId,
  VesselNameSelector,
  // MapLayersSelector,
  // BaseMapLayersSelector,
  ActiveTraceConfigSelector,
  TraceConfigSelector,
  SelectedOverlayMapLayers,
  SelectedBaseMapLayers,
  InfoWindowIsActiveSelector,
  FleetShowNamesSelector,
  ToolsLayerSelector,
  RealtimeIsActiveSelector,
  ActiveLayersMapSelector,
  SelectedParticularsSelector,
  SelectedParticularVesselTypeSelector,
  VesselParticularsSelector,
  SelectedReferencesSelector,
  XYStatics,
  MapZoomSelector,
  MapBoundsSelector,
  RightBarViewSelector,
  SelectedMetaSelector,
  IsSidebarOpenSelector,
  WindyLayerSelector,
  WindyObjectLayerSelector,
  SelectedVesselLatLng,
  MapClicksSelector,
  AggregationSizeSelector,
  AggregationStepSelector,
  OffsetStepSelector,
  RightBarLoadingSelector,
  TraceLoadingSelector,
  InfoWindowLoadingSelector,
  FleetLoadingSelector,
  TooltipSelector,
  InfoWindowActiveTabSelector,
  SidebarLoadingSelector,
  DatesSelector,
};
