import { createReducer, on } from '@ngrx/store';
import {
  ApplyTimezoneToLocalDate,
  DateToItsTimezone,
} from '@core/constants/time.defaults';
import {
  ExtendTraceRangeAction,
  SetActiveTraceAction,
  SetMapLayersConfigAction,
  SetRealtimeFlagAction,
  SetSelectedVesselAction,
  SetSidebarHeadExpandAction,
  SetTraceConfigAction,
  SetVesselsAction,
  ToggleVesselNameInfoAction,
  UpdateActiveTrace,
  SetActiveOverlayMapLayer,
  SetActiveBaseMapLayer,
  MapZoomAction,
  SetRightBar,
  SetSidebarExpandAction,
  ToggleWindyLayerAction,
  SetValueDmsMapClicksAction,
  SetSystemDatesAction,
  SetVesselWindowAction,
  ToggleDmsAction,
  SetAggregationSizeAction,
  SetAggregationStepAction,
  SetOffsetStepAction,
  FleetApiActions,
  SetTimezoneAction,
  SetTooltipAction,
  SetVesselInfoTabAction,
  VesselSidebarApiActions,
  VesselInfoWindoApiActions,
  MapMoveAction,
} from '@dashboard/dashboard-store/actions';
import {
  CancelWeatherLoadingAction,
  GetWeatherDataAction,
  GetWeatherDataErrorAction,
  GetWeatherDataSuccessAction,
} from '@dashboard/dashboard-store/actions/weather.actions';

import moment from 'moment';
import { TraceApiActions } from '../actions/trace.actions';
import { DashboardUiState } from '@dashboard/dashboard-store/reducers/ui-state.initial';
import { IDashboardUiState } from '@dashboard/dashboard-store/dashboard.state';
// import { SetRealtimeFlagAction, SetSelectedVesselAction, SetSidebarExpandAction, SetSidebarHeadExpandAction, SetSystemDatesAction, SetVesselsAction } from '@dashboard/dashboard-store/actions';
export const dashboardUiStateReducer = createReducer(
  DashboardUiState,
  on(SetSystemDatesAction, (state, dates) => {
    const tz = state.timezone;

    const { toDate, fromDate } = dates;

    // Convert toDate and current date with timezone adjustment
    const toDateF = ApplyTimezoneToLocalDate(toDate, tz).moment();
    const fromDateF = ApplyTimezoneToLocalDate(fromDate, tz).moment();
    const currDateF = ApplyTimezoneToLocalDate(moment(), tz).moment();

    // If toDate is in the future, reset toDate to the current date
    if (toDateF > currDateF) {
      return {
        ...state,
        ...dates,
        toDate: DateToItsTimezone(moment(), 0),
      };
    }

    // If toDate is before fromDate, set fromDate to be two days before toDate
    if (toDateF.isBefore(fromDateF, 'm')) {
      const newFromDate = toDateF.clone().subtract(2, 'd').toString();
      return {
        ...state,
        ...dates,
        fromDate: newFromDate,
      };
    }

    // Default case, return updated state as is
    return { ...state, ...dates };
  }),

  on(SetVesselsAction, (state, { vessels }) => {
    return { ...state, vessels };
  }),
  on(SetRealtimeFlagAction, (state, { enableRealtime }) => {
    return { ...state, realtimeIsActive: enableRealtime };
  }),
  on(SetSelectedVesselAction, (state, { vesselId: selectedVessel }) => {
    return {
      ...state,
      selectedVessel,
      sideBar: {
        ...state.sideBar,
        isSidebarOpen: !selectedVessel ? false : state.sideBar.isSidebarOpen,
      },
    };
  }),

  on(SetSidebarExpandAction, (state, { isSidebarOpen }) => {
    return {
      ...state,
      sideBar: {
        ...state.sideBar,
        isSidebarOpen,
      },
    };
  }),

  on(SetSidebarHeadExpandAction, (state) => {
    return {
      ...state,
      sideBar: {
        ...state.sideBar,
        isSidebarHeadExpanded: !state.sideBar.isSidebarHeadExpanded,
      },
    };
  }),

  on(SetVesselWindowAction, (state) => {
    return {
      ...state,
      sideBar: {
        ...state.sideBar,
        isVesselInfoExpaded: !state.sideBar.isVesselInfoExpaded,
      },
    };
  }),

  on(SetVesselInfoTabAction, (state, { tab }) => {
    return {
      ...state,
      sideBar: {
        ...state.sideBar,
        activeTab: tab,
      },
    };
  }),

  on(SetTraceConfigAction, (state, { traceConfig }) => {
    return { ...state, trace: { ...state.trace, traceConfig } };
  }),
  on(SetActiveTraceAction, (state, { activeConfigId }) => {
    return { ...state, trace: { ...state.trace, activeConfigId } };
  }),
  on(ExtendTraceRangeAction, (state, { configId, range }) => {
    const traceConfig = state.trace.traceConfig.map((config) =>
      configId == config.id ? { ...config, range } : config
    );
    return { ...state, trace: { ...state.trace, traceConfig } };
  }),
  on(SetMapLayersConfigAction, (state, { baseMaplayers, overlayLayers }) => {
    //If layers empty we keep the default layers that are initialy seted to store
    return {
      ...state,
      map: { ...state.map, layers: overlayLayers, baseLayers: baseMaplayers },
    };
  }),
  on(SetActiveBaseMapLayer, (state, { layerId: layerId }) => {
    return { ...state, map: { ...state.map, selectedBaseLayer: layerId } };
  }),
  on(SetActiveOverlayMapLayer, (state, { layerId: layerId }) => {
    const activeLayers = state.map.selectedOverlayLayers;
    let newOverlayArray: IDashboardUiState['map']['selectedOverlayLayers'] = [];

    if (activeLayers.includes(layerId)) {
      newOverlayArray = activeLayers.filter((id) => id !== layerId);
    } else {
      newOverlayArray.push(...activeLayers, layerId);
    }
    return {
      ...state,
      map: { ...state.map, selectedOverlayLayers: newOverlayArray },
    };
  }),
  on(UpdateActiveTrace, (state, { traceConfig }) => {
    const activeTraceId = state.trace.activeConfigId;

    const traceConfigs = state.trace.traceConfig.map((config) =>
      activeTraceId == config.id ? traceConfig : config
    );

    return { ...state, trace: { ...state.trace, traceConfig: traceConfigs } };
  }),
  on(ToggleVesselNameInfoAction, (state) => {
    const showName = !state.map.vessels.showName;
    console.log({ showName });
    return {
      ...state,
      map: {
        ...state.map,
        vessels: { ...state.map.vessels, showName },
      },
    };
  }),

  on(ToggleWindyLayerAction, (state, { windyType }) => {
    return {
      ...state,
      map: {
        ...state.map,
        windLayers: {
          ...state.map.windLayers,
          windyType,
        },
      },
    };
  }),
  on(MapZoomAction, (state, { zoom }) => {
    return {
      ...state,
      map: {
        ...state.map,
        zoom,
      },
    };
  }),
  on(MapMoveAction, (state, { bounds }) => {
    return {
      ...state,
      map: {
        ...state.map,
        bounds,
      },
    };
  }),
  on(ToggleDmsAction, (state) => {
    return {
      ...state,
      map: {
        ...state.map,
        dms: { ...state.map.dms, enabled: !state.map.dms.enabled },
      },
    };
  }),
  on(SetValueDmsMapClicksAction, (state, dmsState) => {
    return {
      ...state,
      map: {
        ...state.map,
        dms: { ...state.map.dms, ...dmsState },
      },
    };
  }),
  on(SetRightBar, (state, { selected }) => {
    return { ...state, rightBar: { ...state.rightBar, view: selected } };
  }),

  on(SetAggregationSizeAction, (state, { aggSize }) => {
    return { ...state, aggSize: aggSize };
  }),

  on(SetAggregationStepAction, (state, { aggStep }) => {
    return { ...state, aggStep: aggStep };
  }),

  on(SetOffsetStepAction, (state, { step }) => {
    return { ...state, offsetStep: step };
  }),
  on(GetWeatherDataAction, (state) => {
    return {
      ...state,
      map: {
        ...state.map,
        windLayers: { ...state.map.windLayers, loading: true },
      },
    };
  }),
  on(GetWeatherDataSuccessAction, (state) => {
    return {
      ...state,
      map: {
        ...state.map,
        windLayers: { ...state.map.windLayers, loading: false },
      },
    };
  }),
  on(GetWeatherDataErrorAction, (state) => {
    return {
      ...state,
      map: {
        ...state.map,
        windLayers: { ...state.map.windLayers, loading: false },
      },
    };
  }),
  on(CancelWeatherLoadingAction, (state) => {
    return {
      ...state,
      map: {
        ...state.map,
        windLayers: { ...state.map.windLayers, loading: false },
      },
    };
  }),
  on(TraceApiActions.success, (state) => {
    return {
      ...state,
      trace: {
        ...state.trace,
        loading: false,
      },
    };
  }),
  on(TraceApiActions.failure, (state) => {
    return {
      ...state,
      trace: {
        ...state.trace,
        loading: false,
      },
    };
  }),
  on(TraceApiActions.call, (state) => {
    return {
      ...state,
      trace: {
        ...state.trace,
        loading: true,
      },
    };
  }),
  on(FleetApiActions.call, (state) => {
    return {
      ...state,
      map: {
        ...state.map,
        vessels: {
          ...state.map.vessels,
          loading: true,
        },
      },
    };
  }),
  on(FleetApiActions.success, (state) => {
    return {
      ...state,
      map: {
        ...state.map,
        vessels: {
          ...state.map.vessels,
          loading: false,
        },
      },
    };
  }),
  on(FleetApiActions.failure, (state) => {
    return {
      ...state,
      map: {
        ...state.map,
        vessels: {
          ...state.map.vessels,
          loading: false,
        },
      },
    };
  }),
  on(VesselSidebarApiActions.call, (state) => {
    return { ...state, sideBar: { ...state.sideBar, sidebarLoading: true } };
  }),
  on(VesselSidebarApiActions.failure, (state) => {
    return { ...state, sideBar: { ...state.sideBar, sidebarLoading: false } };
  }),
  on(VesselSidebarApiActions.success, (state) => {
    return { ...state, sideBar: { ...state.sideBar, sidebarLoading: false } };
  }),

  on(VesselInfoWindoApiActions.call, (state) => {
    return {
      ...state,
      sideBar: { ...state.sideBar, infoExpandedLoading: true },
    };
  }),
  on(VesselInfoWindoApiActions.failure, (state) => {
    return {
      ...state,
      sideBar: { ...state.sideBar, infoExpandedLoading: false },
    };
  }),
  on(VesselInfoWindoApiActions.success, (state) => {
    return {
      ...state,
      sideBar: { ...state.sideBar, infoExpandedLoading: false },
    };
  }),
  on(SetTimezoneAction, (state, { timezone }) => {
    return {
      ...state,
      timezone,
    };
  }),
  on(SetTooltipAction, (state, { value }) => {
    return { ...state, tooltipTemplate: value };
  })
);
